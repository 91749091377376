*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.App{
  height: 100vh;
  display: flex;
  gap: 20px;
  padding:40px;
}

/*.custom-scroll::-webkit-scrollbar{
  width: 8px;
}
.custom-scroll::-webkit-scrollbar-track{
  background-color: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb{
  border-radius: 20px;
  background-color: lightgray;
  transition: 300ms;
}
.custom-scroll::-webkit-scrollbar-thumb:hover{
  background-color: gray;
}*/